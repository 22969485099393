<template>
  <div>
    <PageLoader />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout justify-center wrap>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout wrap>
          <v-flex xs6>
            <span class="homeTop">Students Approval</span>
          </v-flex>
          <v-flex xs12 py-3>
            <v-layout wrap justify-end>
              <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-select
                  v-model="fproject"
                  :items="projlist"
                  item-text="name"
                  item-value="_id"
                  outlined
                  clearable
                  dense
                  hide-details
                  class="rounded-xl"
                  label="Project"
                >
                </v-select>
              </v-flex>

              <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-select
                  v-model="fbatch"
                  :items="bacthlist"
                  item-text="name"
                  item-value="_id"
                  outlined
                  clearable
                  dense
                  hide-details
                  class="rounded-xl"
                  label="Batch"
                >
                </v-select>
              </v-flex>

              <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-select
                  v-model="ftype"
                  :items="typelist"
                  outlined
                  clearable
                  dense
                  hide-details
                  class="rounded-xl"
                  label="Type"
                >
                </v-select>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start v-if="list.length > 0">
          <v-flex xs12>
            <v-simple-table fixed-header style="background:#f5f5f5">
              <template v-slot:default>
                <thead>
                  <tr class="poppinssemibold">
                    <th class="text-left">
                      Photo
                    </th>
                    <th class="text-left">
                      Name
                    </th>
                    <th class="text-left">
                      Batch
                    </th>
                    <th class="text-left">
                      No. of months
                    </th>
                    <th class="text-left">
                      Project
                    </th>
                    <th class="text-left">
                      Type
                    </th>
                    <th class="text-left">
                      Status
                    </th>
                    <th class="text-left">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="poppinsregular" v-for="(item, i) in list" :key="i">
                    <td>
                      <v-layout wrap v-if="item">
                        <v-flex
                          align-self-center
                          v-if="item.photo"
                          class="rounded-lg"
                        >
                          <v-img
                            width="45px"
                            height="45px"
                            class="rounded-lg"
                            contain
                            :src="mediaURL + item.photo"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="#000"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-flex>
                        <v-flex xs12 align-self-center v-else>
                          <v-img
                            height="45px"
                            width="45px"
                            contain
                            :src="
                              require('./../../../assets/images/no-image.png')
                            "
                          ></v-img>
                        </v-flex>
                      </v-layout>
                    </td>
                    <td>{{ item.name }}</td>
                    <td>
                      <span v-if="item.batch">
                        {{ item.batch.name }}
                      </span>
                      <span v-else>
                        N/A
                      </span>
                    </td>
                    <td>
                      <span v-if="item.numberOfMonths">
                        {{ item.numberOfMonths }}
                      </span>
                      <span v-else>
                        N/A
                      </span>
                    </td>
                    <td>
                      <span v-if="item.project">
                        {{ item.project.name }}
                      </span>
                      <span v-else>
                        N/A
                      </span>
                    </td>
                    <td>
                      <span v-if="item.type">
                        {{ item.type }}
                      </span>
                      <span v-else>
                        N/A
                      </span>
                    </td>
                    <td>
                      <span v-if="item.internshipStatus">
                        {{ item.internshipStatus }}
                      </span>
                      <span v-else>
                        N/A
                      </span>
                    </td>
                    <td class="text-left">
                      <v-layout wrap justify-start>
                        <!-- <v-flex xs3 align-self-center pa-2 text-left>
                          <v-dialog
                            persistent
                            v-model="item.delete"
                            max-width="600px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                style="font-family: poppinssemibold"
                                class="mr-2"
                                fab
                                icon
                                depressed
                                dark
                                title="Delete"
                                v-on="on"
                                v-bind="attrs"
                                color="error"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-layout wrap>
                                <v-flex
                                  xs12
                                  align-self-center
                                  pa-4
                                  style="background: #000"
                                  text-left
                                >
                                  <span
                                    class="poppinssemibold"
                                    style="color: #ffffff"
                                    >Delete Confirmation</span
                                  >
                                </v-flex>
                              </v-layout>
                              <v-card-title
                                >Are you sure you want to delete this
                                Student?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue"
                                  tile
                                  text
                                  dark
                                  @click="item.delete = false"
                                  >Cancel</v-btn
                                >
                                &nbsp;
                                <v-btn
                                  color="error"
                                  text
                                  tile
                                  @click="deleteItem(item)"
                                  >Delete</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-flex> -->

                        <!-- <v-flex xs3 align-self-center pa-2 text-left>
                          <v-dialog v-model="item.view" max-width="79%">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="#000"
                                style="font-family: poppinssemibold"
                                small
                                dark
                                icon
                                title="View Student"
                                depressed
                                fab
                                v-on="on"
                                v-bind="attrs"
                              >
                                <v-icon>mdi-eye-settings-outline</v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-layout wrap class="poppinsregular">
                                <v-flex
                                  xs12
                                  align-self-center
                                  pa-4
                                  style="background: #000"
                                  text-left
                                >
                                  <span
                                    class="poppinssemibold"
                                    style="color: #ffffff"
                                    >Student Details</span
                                  >
                                </v-flex> 
                                <v-flex xs12 pa-12>
                                  <v-layout wrap v-if="item">
                                    <v-flex
                                      xs12
                                      pa-2
                                      align-self-center
                                      v-if="item.photo"
                                      class="rounded-lg"
                                    >
                                      <v-img
                                        width="133px"
                                        height="133px"
                                        class="rounded-lg"
                                        contain
                                        :src="mediaURL + item.photo"
                                      >
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                          >
                                            <v-progress-circular
                                              indeterminate
                                              color="#000"
                                            ></v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-flex>
                                    <v-flex xs12 pa-2 align-self-center v-else>
                                      <v-img
                                        height="133px"
                                        width="133px"
                                        contain
                                        :src="
                                          require('./../../../assets/images/no-image.png')
                                        "
                                      ></v-img>
                                    </v-flex>
                                  </v-layout>
                                  <v-layout wrap pa-2 v-if="item.name">
                                    <v-flex xs12 align-self-center text-left>
                                      <span
                                        class="poppinssemibold"
                                        style="font-size:22px"
                                      >
                                        {{ item.name }}
                                      </span>
                                    </v-flex>
                                  </v-layout>

                                  <v-layout wrap>
                                    <v-flex
                                      xs6
                                      align-self-center
                                      text-left
                                      pa-3
                                    >
                                      <span v-if="item.phone">
                                        <v-text-field
                                          readonly
                                          v-model="item.phone"
                                          dense
                                          label="Phone"
                                          hide-details
                                        ></v-text-field>
                                      </span>
                                    </v-flex>
                                    <v-flex
                                      xs6
                                      align-self-center
                                      text-left
                                      pa-3
                                    >
                                      <span v-if="item.email">
                                        <v-text-field
                                          readonly
                                          v-model="item.email"
                                          dense
                                          label="Email"
                                          hide-details
                                        ></v-text-field>
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs6
                                      align-self-center
                                      text-left
                                      pa-3
                                    >
                                      <span v-if="item.department">
                                        <v-text-field
                                          readonly
                                          v-model="item.department"
                                          dense
                                          label="Department"
                                          hide-details
                                        ></v-text-field>
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs6
                                      align-self-center
                                      text-left
                                      pa-3
                                    >
                                      <span v-if="item.collageBatch">
                                        <v-text-field
                                          readonly
                                          v-model="item.collageBatch"
                                          dense
                                          label="College Batch"
                                          hide-details
                                        ></v-text-field>
                                      </span>
                                    </v-flex>

                                    <v-flex xs12 pa-3 py-6>
                                      <v-divider></v-divider>
                                    </v-flex>

                                    <v-flex
                                      xs6
                                      align-self-center
                                      text-left
                                      pa-3
                                    >
                                      <span v-if="item.batch">
                                        <v-text-field
                                          readonly
                                          v-model="item.batch.name"
                                          dense
                                          label="Internship Batch"
                                          hide-details
                                        ></v-text-field>
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs6
                                      align-self-center
                                      text-left
                                      pa-3
                                    >
                                      <span v-if="item.project">
                                        <v-text-field
                                          readonly
                                          v-model="item.project.name"
                                          dense
                                          label="Internship Project"
                                          hide-details
                                        ></v-text-field>
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs6
                                      align-self-center
                                      text-left
                                      pa-3
                                    >
                                      <span v-if="item.type">
                                        <v-text-field
                                          readonly
                                          v-model="item.type"
                                          dense
                                          label="Development Type"
                                          hide-details
                                        ></v-text-field>
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      align-self-center
                                      text-left
                                      pa-3
                                    >
                                      <br />
                                      <br />
                                      <br />
                                      <span class="poppinsmedium">Remark</span>
                                      <br />
                                      <span v-if="item.remark">
                                        {{ item.remark }}
                                      </span>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue"
                                  tile
                                  text
                                  dark
                                  @click="item.view = false"
                                  >Close</v-btn
                                >
                                &nbsp;
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-flex> -->
                        <v-flex xs3 align-self-center pa-2>
                          <v-btn
                            color="#000"
                            style="font-family: poppinssemibold"
                            small
                            dark
                            icon
                            title="Edit"
                            depressed
                            fab
                            @click="editcat(item)"
                          >
                            <v-icon>mdi-circle-edit-outline</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout v-else justify-center wrap pt-5>
          <v-flex xs12 text-center>
            <span class="homeText">No Data Found.....</span>
          </v-flex>
        </v-layout>
        <v-dialog v-model="editdialog" max-width="79%">
          <v-card>
            <v-card-title>
              <span class="headline">Edit Student</span>
            </v-card-title>
            <v-card-text>
              <v-layout justify-center wrap>
                <v-flex xs12 sm6 pa-2 align-self-center>
                  <v-text-field
                    class="homeText"
                    outlined
                    dense
                    v-model="editingitem.name"
                    :rules="[rules.required]"
                    required
                    label="Full name"
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pa-2 align-self-center>
                  <v-text-field
                    outlined
                    dense
                    maxlength="10"
                    minlength="10"
                    counter
                    :rules="phnoRules"
                    type="number"
                    v-model="editingitem.phone"
                    class="homeText"
                    label="Phone"
                    hide-details
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 pa-2 align-self-center>
                  <v-text-field
                    outlined
                    class="homeText"
                    dense
                    v-model="editingitem.email"
                    label="Email"
                    hide-details
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 pa-2 align-self-center>
                  <v-select
                    class="homeText"
                    :items="bacthlist"
                    label="Select Batch"
                    dense
                    item-text="name"
                    item-value="_id"
                    :rules="[rules.required]"
                    v-model="editingitem.batch"
                    hide-details
                    outlined
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm6 pa-2 align-self-center>
                  <v-select
                    class="homeText"
                    :items="typelist"
                    label="Select Type"
                    dense
                    v-model="editingitem.type"
                    hide-details
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 pa-2 align-self-center>
                  <v-text-field
                    class="homeText"
                    outlined
                    dense
                    v-model="editingitem.department"
                    label="Department"
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 pa-2 align-self-center>
                  <v-text-field
                    class="homeText"
                    outlined
                    dense
                    v-model="editingitem.collageBatch"
                    label="College Batch"
                    hide-details
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 pa-2 align-self-center>
                  <!-- <v-text-field
                    class="homeText"
                    outlined
                    dense
                    v-model="editingitem.internshipStatus"
                    label="Internship Status"
                    hide-details
                  ></v-text-field> -->

                  <v-select
                    :items="internshipStatusList"
                    v-model="editingitem.internshipStatus"
                    label="Internship Status"
                    hide-details
                    outlined
                    class="homeText"
                    dense
                  ></v-select>
                </v-flex>

                <v-flex xs12 pa-2 align-self-center>
                  <v-textarea
                    class="homeText"
                    outlined
                    dense
                    v-model="editingitem.remark"
                    label=" Remarks"
                    hide-details
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 align-self-center>
                  <ImageComp
                    @stepper="winStepper"
                    :singleImage="editingitem.photo"
                    :pageId="editingitem._id"
                    :height="'320'"
                    :width="'320'"
                    :heading="'Upload Profile Pic'"
                    :componentType="'coverImage'"
                  />
                </v-flex>
                <v-flex xs12 align-self-center pt-12>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm6 pa-2 align-self-center>
                      <v-select
                        class="homeText"
                        :items="projlist"
                        label="--Select Project--"
                        dense
                        item-text="name"
                        item-value="_id"
                        :rules="[rules.required]"
                        v-model="editingitem.project"
                        outlined
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 pa-2 align-self-center>
                      <v-select
                        :items="numberOfMonthsList"
                        v-model="editingitem.numberOfMonths"
                        item-text="name"
                        item-value="val"
                        label="Period of Internship in months"
                        :rules="[rules.required]"
                        outlined
                        class="homeText"
                        dense
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-spacer></v-spacer>
                </v-flex>
                <v-flex xs12 align-self-center pa-4>
                  <v-layout wrap justify-center>
                    <!-- <v-flex xs12 sm6 md4 lg3 align-self-center text-center pa-2>
                      <v-btn
                        color="black darken-1"
                        dark
                        block
                        @click="(editdialog = false), getList()"
                      >
                        Close
                      </v-btn>
                    </v-flex> -->
                    <v-flex xs12 sm6 md4 lg3 align-self-center text-center pa-2>
                      <v-dialog
                        persistent
                        v-model="editingitem.delete"
                        max-width="600px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="black"
                            block
                            v-on="on"
                            v-bind="attrs"
                            class="rounded-xl"
                            dark
                          >
                            Reject
                          </v-btn>
                        </template>
                        <v-card>
                          <v-layout wrap>
                            <v-flex
                              xs12
                              align-self-center
                              pa-4
                              style="background: #000"
                              text-left
                            >
                              <span class="kumbhBold" style="color: #ffffff"
                                >Confirmation</span
                              >
                            </v-flex>
                          </v-layout>
                          <v-card-title
                            >Are you sure you want to reject this
                            request?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green"
                              tile
                              dark
                              @click="editingitem.delete = false"
                              >Cancel</v-btn
                            >
                            &nbsp;
                            <v-btn
                              color="#FF6037"
                              outlined
                              tile
                              @click="rejectStudent(editingitem._id)"
                              >Reject</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-flex>

                    <v-flex xs12 sm6 md4 lg3 align-self-center text-center pa-2>
                      <v-btn
                        color="#FF6037"
                        block
                        dark
                        class="rounded-xl"
                        @click="validateEdit(editingitem._id)"
                      >
                        Approve Student
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="black darken-1"
                text
                @click="(editdialog = false), getList()"
              >
                Close
              </v-btn>
              <!-- <v-btn
                color="blue darken-1"
                text
                @click="validateEdit(editingitem._id)"
              >
                Save
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#000"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "../../../store";

import ImageComp from "./singleImage";
export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      list: [],
      editingitem: [],
      dialog: false,
      addslider: false,
      editdialog: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      imageArray: [],
      imageError: "",
      imageCoverError: "",
      formData: new FormData(),
      selectedFiles: [],
      image: null,
      imagePreview: "",
      showPreview: false,
      file: null,
      imagePreviewCover: "",
      showPreviewCover: false,
      file1: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      selectedImage: null,
      topimageFile: null,
      imageFiles: [],
      id: null,
      bacthlist: [],
      batch: null,
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      name: null,
      remark: null,
      rules: {
        required: (value) => !!value || "Required.",
      },

      emailRules: [
        // (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phnoRules: [
        (v) => !!v || "Phone is required",
        (v) => v.length < 11 || "Invalid Phone",
        (v) =>
          /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/.test(v) ||
          "Phone is invalid",
      ],
      typelist: ["Nodejs", "Vuejs", "Flutter"],
      type: null,
      projlist: [],
      project: null,
      email: "",
      phone: "",
      department: null,
      collageBatch: null,
      internshipStatus: null,
      internshipStatusList: ["On Progress", "Completed"],
      ftype: null,
      fbatch: null,
      fproject: null,
      currentPage: 1,
      pages: 0,
      pageCount: 1,
      flagg: true,
      numberOfMonths: null,
      numberOfMonthsList: [
        {
          name: "1 month",
          val: "1",
        },
        {
          name: "2 months",
          val: "2",
        },
        {
          name: "3 months",
          val: "3",
        },
        {
          name: "4 months",
          val: "4",
        },
        {
          name: "5 months",
          val: "5",
        },
        {
          name: "6 months",
          val: "6",
        },
      ],

      coverImageFile: null,
    };
  },
  watch: {
    ftype() {
      this.currentPage = 1;
      this.getList();
    },

    fproject() {
      this.currentPage = 1;
      this.getList();
    },

    fbatch() {
      this.currentPage = 1;
      this.getList();
    },
    currentPage() {
      this.getList();
    },
  },
  beforeMount() {
    this.fromDate = null;
    this.toDate = null;
    this.getBatch();
    this.getProject();
  },
  mounted() {
    this.getList();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "coverImage") {
        this.coverImageFile = window_data.selectedFiles;
      }
    },
    showImage(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.showImage.value = null;
        this.imagePreview = e.target.result;
      };
      if (this.file.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showSnackBar = true;
        return;
      }
      this.showPreview = true;
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    getBatch() {
      store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "/internship/batch/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.bacthlist = response.data.data;
          store.commit("appLoading", false);
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    getProject() {
      axios({
        method: "GET",
        url: "/internship/project/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.projlist = response.data.data;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    getList() {
      store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "/internship/candidate/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          type: this.ftype,
          batch: this.fbatch,
          project: this.fproject,
          page: this.currentPage,
          count: 20,
          status: "Pending",
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.list = response.data.data;
          // for( let i=0;i<this.list.length;i++) {
          //   if(this.list[i].numberOfMonths) {
          //     this.list[i].numberOfMonths=JSON.parse(this.list[i].numberOfMonths)
          //   }
          // }
          this.pages = response.data.pages;
          store.commit("appLoading", false);
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    resetE() {
      this.$refs.addcat.resetValidation();
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    validateInput() {
      if (this.email) {
        if (!this.validateEmail(this.email)) {
          this.msg = "Please provide a valid email id";
          this.showSnackBar = true;
          return;
        }
      }
      if (!this.name) {
        this.msg = "Please provide student name";
        this.showSnackBar = true;
        return;
      } else if (!this.batch) {
        this.msg = "Please provide batch";
        this.showSnackBar = true;
        return;
      } else if (!this.project) {
        this.msg = "Please provide project";
        this.showSnackBar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please provide phone";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
    clearOff() {
      this.name = null;
      this.email = "";
      this.phone = "";
      this.type = null;
      this.department = null;
      this.collageBatch = null;
      this.internshipStatus = null;
      this.batch = null;
      this.project = null;
      this.remark = null;
    },
    add() {
      axios({
        method: "post",
        url: "/internship/candidate/add",
        data: {
          name: this.name,
          email: this.email,
          phone: this.phone,
          type: this.type,
          department: this.department,
          collageBatch: this.collageBatch,
          internshipStatus: this.internshipStatus,
          batch: this.batch,
          project: this.project,
          remark: this.remark,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            (this.dialog = false), (this.msg = " Added Successully");
            this.showSnackBar = true;

            if (this.coverImageFile) {
              this.uploadImage(response.data.id);
            } else this.getList();
            this.clearOff();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    uploadImage(item) {
      this.appLoading = true;
      let formData = new FormData();
      formData.append("photo", this.coverImageFile);
      formData.append("id", item);
      axios({
        method: "POST",
        url: "/internship/candidate/photo",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Upload Successfully";
            this.showSnackBar = true;
            this.imagePreview = null;
            this.dialog = false;
            this.editdialog = false;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },

    validateEdit() {
      if (this.editingitem.email) {
        if (!this.validateEmail(this.editingitem.email)) {
          this.msg = "Please provide a valid email id";
          this.showSnackBar = true;
          return;
        }
      }
      if (!this.editingitem.name) {
        this.msg = "Please provide student name";
        this.showSnackBar = true;
        return;
      } else if (!this.editingitem.batch) {
        this.msg = "Please provide batch";
        this.showSnackBar = true;
        return;
      } else if (!this.editingitem.project) {
        this.msg = "Please provide project";
        this.showSnackBar = true;
        return;
      } else if (!this.editingitem.numberOfMonths) {
        this.msg = "Please provide period of internship";
        this.showSnackBar = true;
        return;
      } else if (!this.editingitem.phone) {
        this.msg = "Please provide phone";
        this.showSnackBar = true;
        return;
      } else {
        this.edit();
      }
    },
    edit() {
      this.appLoading = true;

      var user = {};
      user["name"] = this.editingitem.name;
      user["id"] = this.editingitem._id;

      user["email"] = this.editingitem.email;
      user["phone"] = this.editingitem.phone;
      user["type"] = this.editingitem.type;

      user["internshipStatus"] = this.editingitem.internshipStatus;
      user["collageBatch"] = this.editingitem.collageBatch;
      user["department"] = this.editingitem.department;

      user["batch"] = this.editingitem.batch;
      user["project"] = this.editingitem.project;
      user["remark"] = this.editingitem.remark;
      user["status"] = "Active";
      user["numberOfMonths"] = this.editingitem.numberOfMonths;

      axios({
        method: "POST",
        url: "/internship/candidate/approve",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;

            if (this.coverImageFile) {
              this.uploadImage(this.editingitem._id);
            } else this.getList();
          } else {
            this.msg = "Can't Update";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editingitem.batch = item.batch._id;
      if (item.project) this.editingitem.project = item.project._id;
      this.editdialog = true;
    },
    rejectStudent(r) {
      this.appLoading = true;

      var user = {};
      user["id"] = r;
      user["status"] = "Rejected";

      axios({
        method: "POST",
        url: "/internship/candidate/approve",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;

            if (this.coverImageFile) {
              this.uploadImage(this.editingitem._id);
            } else this.getList();
          } else {
            this.msg = "Can't Update";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/internship/candidate/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " "
      //  +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;
      return strTime;
    },
  },
};
</script>
<style>
.icon {
  padding-left: 170px;
}
.texttt {
  line-height: 1.5em;
  min-height: 3.5em;
}
.showLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.v-application .primary {
  background-color: red !important;
}
</style>
